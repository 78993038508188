header {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background-color: #fff;
}

.arr_nav {
  margin-top: -3px;
}

.nav_section {
  height: 85px;
}

.hamburger {
  display: none;
}

.menu {
  margin: 0 -80px;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu_light {
  /* padding: 0 10px; */
  list-style-type: none;
  display: flex;
  justify-content: center;
  /* padding-right: 50px; */
  margin-right: 20px;
  margin-left: -75px;
  /* list-style-type: none; */
}

.menu_item {
  text-decoration: none;
  line-height: 100px;
  padding: 0px 20px;
  padding-top: 20px;
  display: block;
}

.logo {
  width: 190%;
  /* padding: -300px; */
  /* margin-top: -10px; */
}

.login_button_1 {
  border: 3px solid #eba14f;
  padding: 10px 15px;
  transition: 0.2s all ease;
}

.menu_link {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
}

.menu_link_bolder {
  color: #342c41;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
}

.menu_link_bolder_infochem {
  color: #342c41;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
  /* margin-left: -30px; */
}

.registration_button_1 {
  border: 3px solid white;
  padding: 10px 15px;
  margin-left: -18px;
  transition: all 0.2s ease;
}

.registration_button_1:hover {
  border: 3px solid black;
}

.registration_button_1:hover + .login_button_1 {
  background-color: red;
}

.login_button_1:hover {
  background-color: #eba14f;
}

.menu_link_bolder_reg {
  color: #342c41;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
}

.menu_item_login {
  text-decoration: none;
  line-height: 100px;
  /* padding: 0px 20px; */
  padding-top: 20px;
  margin-top: -121px;
  margin-left: 115px;
}

.menu_item_registration {
  text-decoration: none;
  line-height: 100px;
  padding-top: 20px;
  margin-left: -30px;
}

.menu_item_registration:hover ~ .menu_item_login .login_button_1 {
  border: 3px solid white;
}

@media (max-width: 1600px) {
  .menu_item .menu_link {
    font-size: 16px;
  }
  .menu_item .menu_link_bolder_infochem {
    font-size: 17px;
  }
  .menu_item_registration .menu_link_bolder_reg {
    font-size: 17px;
    margin-left: -100px;
  }
  .menu_item_login .menu_link_bolder {
    font-size: 17px;
    margin-left: -80px;
  }
  .menu_light {
    margin-right: 80px;
  }
  .menu {
    margin: 0;
  }
}

@media screen and (max-width: 1400px) {
  .menu_item .menu_link {
    font-size: 14px;
  }
  .menu_item .menu_link_bolder_infochem {
    font-size: 15px;
  }
  .menu_item_registration .menu_link_bolder_reg {
    font-size: 15px;
    margin-left: -20px;
  }
  .menu_item_login .menu_link_bolder {
    font-size: 15px;
    margin-left: -40px;
  }
  .menu_light {
    margin-right: 0px;
    margin-left: -69px;
  }
  .menu {
    margin: 0;
  }
}

@media screen and (max-width: 1200px) {
  .menu_item .menu_link {
    font-size: 11px;
  }
  .menu_item .menu_link_bolder_infochem {
    font-size: 13px;
  }
  .menu_item_registration .menu_link_bolder_reg {
    font-size: 13px;
    margin-left: -50px;
  }
  .menu_item_login .menu_link_bolder {
    font-size: 13px;
    margin-left: -80px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .menu_item .menu_link {
    font-size: 12px;
  }

  .menu_item .menu_link_bolder_infochem {
    font-size: 12px;
    /* margin-right: -1000px; */
  }
  .menu_item_registration .menu_link_bolder_reg {
    font-size: 12px;
    margin-left: -20px;
  }
  .menu_item_login .menu_link_bolder {
    font-size: 12px;
    /* margin-right: 100px; */
    margin-left: -40px;
  }
  /* .menu {
    padding-right: -100px;
    padding-left: -100px;
  } */
  .nav_section {
    width: 100%;
    margin-right: -1000px;
  }
}

.login_button_2_text {
  color: #000;
}

@media (min-width: 768px) and (max-width: 992px) {
  .menu_item .menu_link {
    font-size: 10px;
    margin-left: -30px;
  }
  .menu_item .menu_link_bolder_infochem {
    font-size: 10px;
    margin-left: -20px;
    margin-left: -50px;
  }
  .menu_item_registration .menu_link_bolder_reg {
    font-size: 10px;
    margin-left: -20px;
  }
  .menu_item_login .menu_link_bolder {
    font-size: 10px;
    /* margin-right: 100px; */
  }

  .logo {
    margin-left: -50px;
    width: 100px;
  }
}

.infochem_again_text {
  display: none;
}

.login_button_2 {
  display: none;
}

@media (max-width: 767px) {
  .menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 60%;
    height: 100vh;
    padding: 20px 20px;
    flex-direction: column;
    background-color: rgba(214, 214, 214, 0.9);
    align-items: center;
    justify-content: center;
    transition: 0.6s all;
    z-index: 100;
    justify-content: start;
  }

  .registration_button_1 {
    display: none;
  }

  .login_button_2 {
    font-size: 16px;
    font-weight: 600;
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -17px;
    padding-right: 10px;
  }

  .infochem_again_text {
    font-size: 16px;
    font-weight: 700;
    display: block;
    position: absolute;
    left: 34%;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -17px;
  }

  .menu_item_registration .menu_link_bolder_reg {
    margin-left: 16px;
    font-size: 16px;
  }
  .menu_item_login .menu_link_bolder {
    margin-left: 10px;
    font-size: 16px;
    display: none;
  }
  .menu_active {
    right: 0; /* При активации меню, сдвигаем его вправо на 0% */
    justify-content: start;
  }

  .menu_light {
    flex-direction: column;
    margin-right: -100px;
  }
  .menu_item {
    padding: 0;
    line-height: 3;
  }

  .logo {
    display: none;
  }
  .menu_link_bolder_infochem {
    display: none;
  }
  .arr_nav {
    display: none;
  }
  .menu_item .menu_link {
    font-size: 20px;
  }
  .nav_section {
    height: 35px;
  }
  .ITMO {
    width: 30%;
    height: 12%;
    margin-left: 67%;
    margin-top: -11%;
  }
  .hamburger {
    display: block;
    position: absolute;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    margin-top: -17px;
    padding-left: 10px;
  }
  .hamburger span {
    display: block;
    height: 4px;
    width: 100%;
    background-color: black;
    margin-bottom: 4px;
    transition: 0.2s all;
  }
  .hamburger_active {
    margin-top: -20px;
  }
  .hamburger_active span {
    margin-bottom: -4px;
  }
  .hamburger_active span:nth-child(1) {
    transform: translateY(3px) rotate(-45deg);
  }
  .hamburger_active span:nth-child(2) {
    display: none;
  }
  .hamburger_active span:nth-child(3) {
    transform: translateY(3px) rotate(45deg);
  }
  .main_text {
    line-height: 46px;
    font-size: 38px;
    /* margin-top: -130px; */
    padding-top: 130px;
  }
  .main_section {
    height: 500px;
  }
  .sec_text {
    font-size: 20px;
    margin-top: -30px;
  }
  .join_button {
    height: 14%;
    width: 40%;
    font-size: 15px;
    font-weight: 700;
    margin-top: 20px;
  }
  .col-md-9 .second_section_main_text {
    font-size: 22px;
  }
  .second_section_third_text {
    line-height: 30px;
  }
  .second_section {
    height: 350px;
  }

  .third_section {
    height: 800px;
  }
  .col-md-4 .third_section_first_text_main {
    font-size: 22px;
    line-height: 30px;
  }
  .col-md-4 .third_section_first_text_sec {
    font-size: 16px;
    line-height: 20px;
  }
  .col-md-4 .third_section_sec_text_main {
    font-size: 22px;
    line-height: 30px;
    margin-left: -65px;
  }
  .col-md-4 .third_section_sec_text_sec {
    font-size: 16px;
    line-height: 20px;
    margin-left: -65px;
  }
  .clothes_icon {
    margin-left: -17px;
  }
  .col-md-4 .third_section_third_text_main {
    font-size: 22px;
    line-height: 30px;
    margin-left: -16px;
  }
  .col-md-4 .third_section_third_text_sec {
    font-size: 16px;
    line-height: 20px;
    margin-left: -16px;
  }
  .flask_icon {
    margin-left: -17px;
  }

  .fourth_section {
    height: 1450px;
  }
  .learn_chem {
    font-size: 30px;
  }
  .first_card {
    height: 70%;
    width: 100%;
    margin-bottom: 10px;
  }
  .fourth_section_first_text_main {
    font-size: 30px;
    /* margin-top: -970px; */
  }
  .fourth_section_first_text_sec {
    font-size: 20px;
    /* margin-top: -920px; */
    /* line-height: 30px; */
  }
  .sec_card {
    height: 70%;
    width: 100%;
    margin-bottom: 10px;
  }
  .fourth_section_sec_text_main {
    font-size: 30px;
  }
  .fourth_section_sec_text_sec {
    font-size: 20px;
  }
  .third_card {
    height: 70%;
    width: 100%;
    margin-bottom: 10px;
  }
  .fourth_section_third_text_main {
    font-size: 30px;
  }
  .fourth_section_third_text_sec {
    font-size: 20px;
  }

  .fifth_section {
    height: 900px;
  }
  .col-md-8 .sci_path {
    font-size: 40px;
  }

  .reg_portal {
    height: 81%;
  }
  .learn_tech {
    height: 71.5%;
    padding: 0;
    /* margin: 0; */
    margin-left: -3px;
  }
  .pass_test {
    height: 71.5%;
    /* margin-left: -10px; */
    padding: 0;
  }
  .labs_res {
    height: 71.5%;
    padding: 0;
    margin-left: -3px;
  }
  .learn_more {
    width: 60%;
    height: 80%;
    margin-top: 20px;
    margin-right: -100px;
    font-size: 20px;
    margin-left: 20%;
  }

  .sixth_section {
    height: 600px;
    padding-top: 100px;
  }
}

@media (min-width: 575.98px) and (max-width: 767.98px) {
  .learn_more {
    width: 300px;
    height: 60px;
    margin-top: 20px;
    margin-right: -100px;
    font-size: 20px;
    margin-left: -80px;
  }
}

@media (min-width: 549.98px) and (max-width: 767px) {
  .sixth_section {
    height: 700px;
    padding-top: 100px;
  }
}

@media (max-width: 549.98px) {
  .main_text {
    font-size: 30px;
  }
  .main_section {
    height: 530px;
  }
  .ITMO {
    height: 35px;
    margin-top: -45px;
  }
  .join_button {
    height: 50px;
    width: 200px;
    margin-top: 70px;
    padding: 4px 33px;
  }
  .second_section {
    height: 450px;
  }
  .third_section {
    height: 1100px;
  }
  .learn_chem {
    font-size: 22px;
  }
  .first_card {
    height: 70%;
    width: 100%;
    margin-bottom: 10px;
  }
  .sec_card {
    height: 70%;
    width: 100%;
    margin-bottom: 10px;
  }
  .third_card {
    height: 70%;
    width: 100%;
    margin-bottom: 10px;
  }
  .fourth_section {
    height: 1300px;
  }
  .fourth_section_first_text_main {
    font-size: 20px;
  }
  .fourth_section_first_text_sec {
    font-size: 18px;
  }
  .fourth_section_sec_text_main {
    font-size: 20px;
  }
  .fourth_section_sec_text_sec {
    font-size: 18px;
  }
  .fourth_section_third_text_main {
    font-size: 20px;
  }
  .fourth_section_third_text_sec {
    font-size: 18px;
  }
  .col-md-8 .sci_path {
    font-size: 22px;
    line-height: 25px;
    padding-bottom: 20px;
  }
  .reg_portal {
    height: 16.3vw;
    padding: 0;
  }
  .learn_tech {
    height: 16.01vw;
  }
  .pass_test {
    height: 17.36vw;
  }
  .labs_res {
    height: 15.45vw;
  }
  .learn_more {
    font-size: 10px;
    padding: 0;
    margin: 10;
  }
  .fifth_section {
    height: 470px;
  }
  .study_plan {
    font-size: 20px;
    line-height: 30px;
  }
  .study_plan_sec {
    font-size: 18px;
    line-height: 24px;
    padding-top: 10px;
  }
  .col-md-10 .contacts_text {
    font-size: 22px;
  }
  .addressy {
    font-size: 15px;
  }
  .tick {
    width: 20px;
    padding-right: 0px;
  }
  .maily {
    font-size: 15px;
  }
  .scorb_mail {
    width: 20px;
    padding-right: 0px;
  }
  .phone {
    width: 20px;
    padding-right: 0px;
  }
  .phone_nummy {
    font-size: 15px;
  }
  .seventh_section {
    height: 0;
  }
  .copy {
    padding-top: 60px;
  }

  .infochem_again_text {
    left: 18%;
  }
}

@media (min-width: 369px) and (max-width: 450px) {
  .fourth_section {
    height: 1200px;
  }
}

@media (min-width: 369px) and (max-width: 549.96px) {
  .third_section {
    height: 700px;
    padding-left: 15px;
  }
  .main_section {
    height: 565px;
  }
}

@media (min-width: 300px) and (max-width: 368.98px) {
  .main_section {
    height: 620px;
  }
  .infochem_again_text {
    font-size: 14px;
  }
  .col-md-9 .second_section_main_text {
    font-size: 18px;
  }
  .second_section {
    height: 500px;
  }
  .third_section {
    height: 800px;
    padding-left: 15px;
  }
  .fourth_section {
    height: 1100px;
  }
  /* .first_card {
    height: 98%;
    padding-bottom: 100px;
    width: 93vw;
  }
  .sec_card {
    height: 249px;
  }
  .third_card {
    height: 170px;
  }
  .fourth_section_sec_text_sec {
    margin-top: -330px;
  }
  .fourth_section_first_text_main {
    margin-top: -640px;
  }
  .fourth_section_first_text_sec {
    margin-top: -600px;
  }
  .fourth_section_sec_text_main {
    margin-top: -365px;
  } */
  /* .reg_portal {
    height: 52px;
  }
  .learn_tech {
    height: 51.3px;
  }
  .pass_test {
    height: 56.2px;
    margin-left: -3px;
  }
  .labs_res {
    height: 49.5px;
  } */
  .fifth_section {
    height: 420px;
  }
  .sixth_section {
    height: 500px;
  }
  .col-md-8 .learn_more {
    margin-right: -20px;
  }
  .contacts_text {
    font-size: 20px;
  }
  .tick {
    height: 30px;
    padding-right: 0;
  }
  .scorb_mail {
    height: 20px;
    padding-right: 0;
  }
  .phone {
    height: 28px;
    padding-right: 0;
  }
  .phone_nummy {
    font-size: 15px;
  }
  .maily {
    font-size: 15px;
  }
  .addressy {
    font-size: 12px;
  }
}
