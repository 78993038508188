.seventh_section {
  height: 430px;
}

.contacts_text {
  color: rgba(0, 0, 0, 0.7);
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  text-transform: uppercase;
}

.spb {
  color: rgba(0, 0, 0, 0.75);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 157.523%; 46.881px */
  padding-top: 30px;
}

.tick {
  padding-right: 20px;
}

.scorb_mail {
  padding-right: 20px;
}

.phone {
  padding-right: 20px;
}

.mail {
  padding-top: 20px;
  color: rgba(0, 0, 0, 0.75);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.phone_b {
  padding-top: 20px;
  color: rgba(0, 0, 0, 0.75);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}

.copy {
  text-align: center;
  color: #342c41;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 187.523%; /* 28.128px */
  padding-top: 120px;
}
