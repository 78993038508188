.groupMembersClass {
  margin-left: 350px;
  margin-top: -230px;
}

.group_members_list {
  font-weight: 600;
}

.FIO_members {
  margin-top: -200px;
}

.uspevaemost2 {
  margin-top: 20px;
}

td {
  border: 1px solid rgb(150, 150, 150);
}

th {
  border: 1px solid rgb(150, 150, 150);
}

.sum_of_bolls {
  width: 35%;
}

.name_of_laborat {
  width: 35%;
}

.work_name {
  width: 30%;
}

.date_of_lab {
  width: 10%;
}

.posesh2 {
  width: 10%;
}

.testovski2 {
  width: 12%;
}

.student_of_the_group {
  margin-top: 20px;
  color: black;
  line-height: 15px;
}

.student_of_the_group:hover {
  font-weight: 600;
}

.student_of_the_group_link {
  text-decoration: none;
}

@media (max-width: 767px) {
  .groupMembersClass {
    margin-left: 0;
    margin-top: 0;
  }
}
