.acc_input-box_redact {
  margin-top: 10px;
  padding-right: 25px;
  padding-top: 10px;
  /* padding-bottom: -150px; */
}

.invis_svg_redact {
  visibility: hidden;
}

.phone_balls_yk input {
  padding: 11px;
  padding-right: 90px;
  /* color: rgba(0, 0, 0, 0.5); */
}

.phone_balls_yk2 input {
  padding: 11px;
  padding-right: 90px;
  /* color: rgba(0, 0, 0, 0.5); */
}

.mail_balls_yk input {
  padding: 11px;
  /* padding-right: 90px; */
  width: 299px;
  margin-top: -10px;
  /* color: rgba(0, 0, 0, 0.5); */
}

.social_urls input {
  padding: 5px 10px;
  margin-right: -40px;
  /* border: none; */
}

.acc_mail {
  margin-top: 10px;
}

/* .name_place svg {
    display: none;
  } */

.acc_photo_red {
  height: 530%;
  /* margin-bottom: 200px; */
  border: 3.4px solid white;
  border-radius: 100%;
  margin-left: -66px;
  margin-top: -597px;
}

.form :where(.acc_input-box_redact input, .select-box) {
  position: relative;
  height: 45px;
  width: 70%;
  outline: none;
  font-size: 1rem;
  color: #707070;
  margin-top: -100px;
  border: 1px solid #000;
  border-radius: 100px;
  padding: 0 10px;
}

.form .acc_input-box_redact {
  transition: all 0.2 ease;
}

.save_redact {
  padding: 8px 32px;
  border: none;
  color: white;
  background-color: #eba14f;
  font-weight: 600;
  margin-left: 1180px;
  margin-top: 200px;
  transition: all ease 0.2s;
}

.save_redact:hover {
  background-color: #fb8b0a;
}

/* @media (min-width: 1200px) and (max-width: 1400px) {
  .save_redact {
    margin-left: 880px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .save_redact {
    margin-left: 750px;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .save_redact {
    margin-left: 350px;
    margin-top: 500px;
  }
}

@media (min-width: 460px) and (max-width: 575.98px) {
  .save_redact {
    margin-left: 300px;
    margin-top: 505px;
  }
}

@media (min-width: 400px) and (max-width: 459.98px) {
  .save_redact {
    margin-left: 230px;
    margin-top: 505px;
  }
}

@media (min-width: 300px) and (max-width: 399.98px) {
  .save_redact {
    margin-left: 190px;
    margin-top: 505px;
  }
} */

@media (max-width: 767px) {
  .save_redact {
    margin: 0;
    margin-top: 600px;
  }
}

@media (max-width: 767px) {
  .mail_balls_yk input {
    width: 101%;
  }
  .social_urls input {
    width: 90%;
  }
}
