.fourth_section {
  height: 500px;
  padding-top: 55px;
}

.learn_chem {
  color: rgba(0, 0, 0, 0.7);
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 83.333% */
  text-transform: uppercase;
}

.first_card {
  padding-top: 30px;
  height: 70%;
  width: 100%;
  margin-bottom: 10px;
  transition: 0.2s all ease;
}

.first_card:hover {
  transform: scale(1.02);
}

.sec_card {
  padding-top: 30px;
  height: 70%;
  width: 100%;
  margin-bottom: 10px;
  transition: 0.2s all ease;
}

.sec_card:hover {
  transform: scale(1.02);
}

.third_card {
  padding-top: 30px;
  height: 70%;
  width: 100%;
  margin-bottom: 10px;
  transition: 0.2s all ease;
}

.third_card:hover {
  transform: scale(1.02);
}

.fourth_section_first_text_main {
  color: #342c41;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 38px; */
  /* margin-top: -40px; */
}

.fourth_section_sec_text_main {
  color: #342c41;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 38px; */
  /* margin-top: -40px; */
  /* padding-left: 30px; */
}

.fourth_section_third_text_main {
  color: #342c41;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 38px; */
  /* margin-top: -40px; */
  /* padding-left: 60px; */
}

.fourth_section_first_text_sec {
  color: #342c41;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 142.857% */
}

.fourth_section_sec_text_sec {
  color: #342c41;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 142.857% */
  /* padding-left: 30px; */
}

.fourth_section_third_text_sec {
  color: #342c41;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* padding-left: 60px; */
}

@media screen and (max-width: 1400px) {
  .first_card {
    height: 70%;
    width: 100%;
    margin-bottom: 10px;
  }
  .sec_card {
    height: 70%;
    width: 100%;
    margin-bottom: 10px;
  }
  .third_card {
    height: 70%;
    width: 100%;
    margin-bottom: 10px;
  }
  .learn_chem {
    font-size: 30px;
  }
  .fourth_section_first_text_main {
    font-size: 16px;
  }
  .fourth_section_sec_text_main {
    font-size: 16px;
    /* margin-top: -90px; */
  }
  .fourth_section_third_text_main {
    font-size: 16px;
    /* margin-top: -90px; */
  }
  .fourth_section_first_text_sec {
    font-size: 14px;
    /* margin-top: -50px; */
    line-height: 20px;
  }
  .fourth_section_sec_text_sec {
    font-size: 14px;
    /* margin-top: -50px; */
    line-height: 20px;
  }
  .fourth_section_third_text_sec {
    font-size: 14px;
    /* margin-top: -50px; */
    line-height: 20px;
  }
  .fourth_section {
    height: 450px;
    padding-top: 55px;
  }
}

@media screen and (max-width: 1200px) {
  .first_card {
    height: 70%;
    width: 100%;
    margin-bottom: 10px;
  }
  .sec_card {
    height: 70%;
    width: 100%;
    margin-bottom: 10px;
  }
  .third_card {
    height: 70%;
    width: 100%;
    margin-bottom: 10px;
  }
  .learn_chem {
    font-size: 30px;
  }
  .fourth_section_first_text_main {
    font-size: 14px;
    /* margin-top: -100px; */
  }

  .fourth_section_sec_text_main {
    font-size: 14px;
    /* margin-top: -100px; */
  }

  .fourth_section_third_text_main {
    font-size: 14px;
    /* margin-top: -100px; */
  }
  .fourth_section_first_text_sec {
    font-size: 13px;
    /* margin-top: -60px; */
  }
  .fourth_section_sec_text_sec {
    font-size: 13px;
    /* margin-top: -60px; */
  }
  .fourth_section_third_text_sec {
    font-size: 13px;
    /* margin-top: -60px; */
  }
  .fourth_section {
    height: 430px;
    /* padding-top: 55px; */
  }
}

@media screen and (max-width: 992px) {
  .first_card {
    height: 70%;
    width: 100%;
    margin-bottom: 10px;
  }
  .sec_card {
    height: 70%;
    width: 100%;
    margin-bottom: 10px;
  }
  .third_card {
    height: 70%;
    width: 100%;
    margin-bottom: 10px;
  }
  .learn_chem {
    font-size: 28px;
  }
  .fourth_section_first_text_main {
    font-size: 14px;
    /* margin-top: -150px; */
  }

  .fourth_section_sec_text_main {
    font-size: 14px;
    /* margin-top: -150px; */
  }

  .fourth_section_third_text_main {
    font-size: 14px;
    /* margin-top: -150px; */
  }
  .fourth_section_first_text_sec {
    font-size: 12px;
    /* margin-top: -120px; */
    /* line-height: 15px; */
  }
  .fourth_section_sec_text_sec {
    font-size: 12px;
    /* margin-top: -120px; */
    /* line-height: 15px; */
  }
  .fourth_section_third_text_sec {
    font-size: 12px;
    /* margin-top: -120px; */
    /* line-height: 15px; */
  }
  .fourth_section {
    height: 450px;
    /* padding-top: 20px; */
  }
}
