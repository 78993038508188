.form .input-box-login {
  margin-left: 15px;
}

.error-message {
  font-size: 20px;
  text-align: center;
  margin-left: -20px;
}

.action_btns_reg .action_btn1_log {
  background-color: #edeeef;
  color: #000;
  padding: 0.5rem 3.2rem;
  border: 3px solid #edeeef;
  outline: none;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}

.action_btns_reg .action_btn2_log {
  background-color: #fff;
  color: #000;
  padding: 0.5rem 5.5rem;
  border: 3px solid #edeeef;
  outline: none;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}

.input-box-login-pswd {
  margin-top: -10%;
}

@media (min-width: 768px) and (max-width: 991.99px) {
  .create_acc {
    font-size: 24px;
    letter-spacing: 2px;
  }
  .form button {
    margin-left: 23%;
  }
  .reg_image {
    display: none;
  }
}

@media (max-width: 575.98px) {
  .action_btns_reg {
    display: none;
  }
  .reg_image {
    display: none;
  }
  .input-box {
    margin-left: 25px;
    padding: 0;
  }
  .form button {
    margin-left: 25%;
    font-size: 12px;
  }
  .create_acc {
    font-size: 25px;
    letter-spacing: 2px;
  }
  .infochemedu .infochemedu {
    font-size: 14px;
    font-weight: 700;
    display: block;
    position: absolute;
    left: 10%;
    letter-spacing: 0px;

    /* top: 50%; */
    transform: translateY(-50%);
    margin-top: -17px;
  }
}
