.second_section {
  height: 400px;
  /* padding-top: 80px; */
  /* margin-top: -40px; */
}

.second_section_third_text {
  text-decoration: none;
}

.second_section_main_text {
  color: rgba(0, 0, 0, 0.7);
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 83.333% */
  text-transform: uppercase;
}
.second_section_third_text {
  color: #342c41;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 40.5px; /* 175% */
  padding-top: 20px;
}

.yellow_span {
  color: #a56c00;
}

.yellow_span:hover {
  color: #b14000;
  cursor: pointer;
}

.orange_span {
  color: #b14000;
}

.card {
  background: #f1f1f1;
  height: 303px;
  margin-top: 60px;
  z-index: -1000;
}

.second_section_fourth_text {
  color: #342c41;
  font-size: 27px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 125.926% */
}

.third_section {
  height: 280px;
  background-color: #f1f1f1;
  padding-left: 40px;
}

.third_section_first_text_main {
  color: #342c41;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 125.926% */
}

.mol_icon {
  margin-top: 40px;
  margin-bottom: 10px;
}
.third_section_first_text_sec {
  color: #342c41;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 119.048% */
}

.third_section_sec_text_main {
  color: #342c41;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 125.926% */
  padding-left: 50px;
  padding-right: 20px;
}

.third_section_sec_text_sec {
  color: #342c41;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 119.048% */
  /* padding-left: 50px; */
  /* padding-right: 50px; */
}

.clothes_icon {
  margin-top: 40px;
  margin-bottom: 10px;
  margin-left: 50px;
}

.third_section_third_text_main {
  color: #342c41;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 34px; /* 125.926% */
  /* padding-left: 50px; */
  /* padding-right: 20px; */
}

.third_section_third_text_sec {
  color: #342c41;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 119.048% */
  padding-left: 50px;
  padding-right: 50px;
}

@media (max-width: 992px) {
  .col-md-9 .second_section_main_text {
    font-size: 28px;
  }
  .col-md-9 .second_section_third_text {
    font-size: 18px;
  }
  .col-md-4 .third_section_first_text_sec {
    font-size: 12px;
    line-height: 20px;
  }
  .col-md-4 .third_section_first_text_main {
    font-size: 13px;
    line-height: 25px;
  }
  .col-md-4 .third_section_sec_text_sec {
    font-size: 12px;
    line-height: 20px;
  }
  .col-md-4 .third_section_sec_text_main {
    font-size: 13px;
    line-height: 25px;
  }
  .col-md-4 .third_section_third_text_sec {
    font-size: 12px;
    line-height: 20px;
  }
  .col-md-4 .third_section_third_text_main {
    font-size: 13px;
    line-height: 25px;
  }
}

@media (max-width: 1200px) {
  .col-md-4 .third_section_first_text_main {
    font-size: 13px;
    line-height: 25px;
  }
  .col-md-4 .third_section_sec_text_sec {
    font-size: 12px;
    line-height: 20px;
  }
  .col-md-4 .third_section_sec_text_main {
    font-size: 13px;
    line-height: 25px;
  }
  .col-md-4 .third_section_third_text_sec {
    font-size: 12px;
    line-height: 20px;
  }
  .col-md-4 .third_section_third_text_main {
    font-size: 13px;
    line-height: 25px;
  }
}
