.PDFS {
  margin-left: 350px;
  margin-top: -220px;
}

.balling_with_test {
  color: #263238;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  margin-top: 25px;
}

.щщщ {
  font-weight: 700;
  font-size: 30px;
}

.usp_lab_dab {
  padding: 10px 0px;
  padding-right: 57px;
  padding-left: 10px;
  border: none;
  color: white;
  background-color: #eba14f;
  font-weight: 600;
}

.set_da_flag {
  margin-left: 10px;
}

.pdf_uploading_balls {
  margin-bottom: 30px;
}

.usp_lab_acc {
  padding: 10px 0px;
  padding-right: 114px;
  padding-left: 10px;
  border: none;
  color: black;
  background-color: white;
  font-weight: 600;
}

.usp_lab_acc:hover {
  color: white;
  background-color: #eba14f;
}

.upload_your_pdf {
  padding: 2px 10px;
  /* padding-right: 64px; */
  padding-left: 10px;
  border: 1px solid;
  color: black;
  background-color: white;
  font-weight: 600;
  font-size: 16px;
  margin-top: -10px;
  padding: 10px;
  /* margin-left: 2px; */
  margin-bottom: 40px;
  margin-top: 10px;
  margin-right: 2px;
}

.upload_your_pdf_otv {
  padding: 10px;
  padding-right: 25px;
  margin-bottom: 10px;
}

.upload_your_pdf_save {
  margin-bottom: 100px;
}

.upload_your_pdf:hover {
  background-color: #eba14f;
  color: white;
}

.input_for_test_name {
  border: 1px solid;
  border-radius: 10px 0px 0px 10px;
  padding-left: 10px;
  margin-bottom: 5px;
  margin-top: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 300px;
  margin-right: 2px;
}

.radio_buttons_for_sections {
  display: flex;
  flex-direction: column;
}

.instruct {
  font-weight: 700;
  font-size: 20px;
}

@media (max-width: 767px) {
  .PDFS {
    margin-left: 0px;
    margin-top: 0px;
  }
  .usp_lab_dab {
    font-size: 12px;
    background-color: rgba(214, 214, 214, 0);
    /* padding-right: ; */
    border: 1px solid;
    color: black;
    padding-right: 72px;
    margin-left: 0px;
  }
  .usp_lab_acc {
    font-size: 12px;
    background-color: rgba(214, 214, 214, 0);
    border: 1px solid;
    color: black;
    margin-left: 0px;
  }
}
