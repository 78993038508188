.add_students_to_group_ball {
  margin-left: 350px;
  margin-top: -230px;
}

.binchillin {
  list-style-type: none;
  margin-left: -30px;
}

.binbinbinchil {
  margin-left: 7px;
  margin-bottom: 7px;
}

.binbinbinchil:hover {
  font-weight: 700;
}

.add_studs_to_balls {
  border: 1px solid;
  color: black;
  background-color: white;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 50px;
  margin-top: 20px;
  padding: 10px 30px;
}

.add_studs_to_balls:hover {
  background-color: #eba14f;
  color: white;
}

.search_for_studs_balls {
  width: 40vw;
  padding-left: 20px;
  border: 1px solid #bebebe;
  border-radius: 10px 10px 10px 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.add_to_group_studs {
  font-weight: 700;
}
