.eight_section {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

.reg_image {
  height: 100vh;
  width: 48.8vw;
  overflow: hidden;
  /* overflow-y: hidden; 
  overflow-x: hidden; */
  /* bottom: 0; */
}

.login_small .login_small_text {
  display: none;
}

.infochemedu {
  text-decoration: none;
  color: #342c41;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.54px;
  padding-top: 40px;
}

.create_acc {
  color: #000;
  font-size: 37.968px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 3.358px;
  text-align: center;
  padding-top: 60px;
}

.action_btns_reg {
  padding-top: 30px;
  padding-left: 90px;
}

.action_btn1_reg {
  text-decoration: none;
  background-color: #fff; /*edeeef*/
  color: #000;
  padding: 0.5rem 3.2rem;
  border: 3px solid #edeeef;
  outline: none;
  /* border-radius: 20px; */
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}

.action_btn2_reg {
  text-decoration: none;
  background-color: #edeeef;
  color: #000;
  padding: 0.5rem 5.5rem;
  border: 3px solid #edeeef;
  outline: none;
  /* border-radius: 20px; */
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}

.form .input-box {
  width: 100%;
  margin-top: 40px;
  padding-right: 25px;
  padding-top: 10px;
}

.input-box label {
  color: #333;
}

.input-box-login label {
  color: #333;
}

.form .input-box-login {
  width: 100%;
  margin-top: 20px;
  padding-right: 25px;
  padding-top: 10px;
}

.form :where(.input-box input, .select-box) {
  position: relative;
  height: 45px;
  width: 100%;
  outline: none;
  font-size: 1rem;
  color: #707070;
  margin-top: -100px;
  border: 1px solid #000;
  /* border-radius: 100px; */
  padding: 0 15px;
}

.input-box input:focus {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}

.input-box-login input:focus {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}

.form .column {
  display: flex;
  column-gap: 15px;
}

.select-box select {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  color: #707070;
  font-size: 1rem;
}
.form button {
  height: 50px;
  width: 50%;
  color: black;
  font-size: 1.15rem;
  font-weight: 600;
  margin-top: 70px;
  margin-left: 150px;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  background: #fad27c;
  /* border-radius: 100px; */
}
.form button:hover {
  background: #f4ba3d;
}
/*Responsive*/
@media screen and (max-width: 500px) {
  .form .column {
    flex-wrap: wrap;
  }
  .form :where(.gender-option, .gender) {
    row-gap: 15px;
  }
  .eight_section {
    height: 150vh;
  }
}

.d1 {
  width: 451px;
  height: 42px;
  background: #edeeef;
  margin-top: -32.5px;
  margin-left: 86px;
}

.form {
  max-height: 740px;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .action_btns_reg {
    padding-left: 45px;
  }
  .form button {
    margin-left: 120px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .action_btns_reg {
    padding-left: 0px;
    margin-left: -5px;
  }
  .form button {
    margin-left: 100px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .action_btns_reg {
    padding-left: 0px;
    margin-left: -5px;
  }
  .form button {
    margin-left: 100px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .reg_image {
    display: none;
  }
  .action_btns_reg {
    padding-left: 0px;
    margin-left: 120px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .reg_image {
    display: none;
  }
  .action_btns_reg {
    padding-left: 0px;
    margin-left: 30px;
  }
  .form button {
    margin-left: 120px;
  }
}

@media (max-width: 575.98px) {
  .action_btns_reg {
    display: none;
  }
  .reg_image {
    display: none;
  }
  .input-box {
    margin-left: 25px;
    padding: 0;
  }
  .form button {
    margin-left: 25%;
    font-size: 12px;
  }
  .create_acc {
    font-size: 25px;
    letter-spacing: 2px;
  }
  .infochemedu .infochemedu {
    font-size: 20px;
    font-weight: 700;
    display: block;
    position: absolute;
    left: 7%;
    transform: translateY(-50%);
    margin-top: -17px;
  }
  .input-box-login-pswd {
    padding-right: 25px;
  }
  .login_small .login_small_text {
    font-size: 14px;
    font-weight: 700;
    display: block;
    position: absolute;
    right: 10%;
    letter-spacing: 0px;
    transform: translateY(-50%);
    top: 44px;
    color: #342c41;
  }
}
