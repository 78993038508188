.groups_list_balls {
  margin-left: 340px;
  margin-top: -231px;
}

.all_the_groups_av {
  list-style-type: none;
}

.link_to_grouppp {
  text-decoration: none;
}

.group_link {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.12px;
}

.group_link:hover {
  color: black;
  font-weight: 600;
}

.group_name_ff {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.12px;
  line-height: 28px;
  margin-left: -30px;
}

.list_of_my_balls {
  color: #263238;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  margin-bottom: 25px;
}

.my_grrr {
  padding: 10px 0px;
  padding-right: 86px;
  padding-left: 10px;
  border: none;
  color: white;
  background-color: #eba14f;
  font-weight: 600;
}

.naz_lab {
  width: 40%;
  /* margin-left: 100px; */
  margin-top: 20px;
  border: 1px solid;
  color: black;
  background-color: white;
  font-weight: 600;
  font-size: 16px;
}

.naz_lab:hover {
  background-color: #eba14f;
  color: white;
}

@media (max-width: 767px) {
  .groups_list_balls {
    margin-left: 0px;
    margin-top: 0px;
  }
  .my_grrr {
    font-size: 12px;
    background-color: rgba(214, 214, 214, 0);
    padding-right: 88px;
    border: 1px solid;
    color: black;
  }
  .usp_lab {
    font-size: 12px;
    background-color: rgba(214, 214, 214, 0);
    /* padding-right: 72px; */
    border: 1px solid;
    color: black;
    /* margin-left: 0; */
  }
  .naz_lab {
    width: 280px;
  }
}
