.one_test {
  margin-left: 350px;
  margin-top: -290px;
}

.one_test h2 {
  color: hsl(200, 19%, 18%);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
}
.balling_stones_1 {
  list-style-type: none;
  margin-left: -31px;
  margin-top: 20px;
}
.balling_stones_2 {
  list-style-type: none;
  margin-left: -31px;
  margin-top: 10px;
  user-select: none;
}

.poposha_test {
  margin-left: 2px;
}

.ques {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-top: 70px;
  margin-bottom: 30px;
}

.balling_stones_2 input {
  margin-right: 15px;
}

.send_answers_to_server {
  padding: 10px 50px;
  margin-top: 60px;
  border-radius: 30px;
  background: #b9c4cc;
  border: 1px;
  color: white;
  font-weight: 600;
  transition: all 0.2s ease;
  margin-bottom: 40px;
  /* margin-left: 200px; */
}

.send_answers_to_server:hover {
  background: #848484;
}

@media (max-width: 767px) {
  .acc_test_test {
    font-size: 12px;
    background-color: rgba(214, 214, 214, 0);
    padding-right: 129px;
    border: 1px solid;
    color: black;
  }

  .center_da_button {
    display: flex;
    justify-content: center;
  }
  .acc_acc_test {
    display: flex;
    border: 1px solid;
    color: black;
    background-color: rgba(214, 214, 214, 0);
    font-weight: 600;
    font-size: 12px;
  }
  .one_test {
    margin-left: 0;
    margin-top: 0;
  }
  .ques {
    margin-top: 40px;
  }
}

.attemps_0 {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin-top: 200px;
  font-size: 30px;
  flex-direction: column;
}

.button_res_2 {
  padding: 10px 40px 10px 40px;
}
