.appointment {
  margin-left: 350px;
  margin-top: -225px;
}

.save-button-for-app {
  margin-bottom: 30px;
  padding: 2px 10px;
  /* padding-right: 64px; */
  padding-left: 10px;
  border: 1px solid;
  color: black;
  background-color: white;
  font-weight: 600;
  font-size: 16px;
}

.save-button-for-app:hover {
  background-color: #eba14f;
  color: white;
}

.name_lab_for_changing {
  font-weight: 600;
}

.submit-button {
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 8px 28px;
  /* padding-right: 64px; */
  /* padding-left: 10px; */
  border: 1px solid;
  color: black;
  background-color: white;
  font-weight: 600;
  font-size: 18px;
}
.submit-button:hover {
  background-color: #eba14f;
  color: white;
}

.AAAAA {
  margin-left: 350px;
  margin-top: -215px;
}

.AAAAAAAAAAAAAAAAAAAAA {
  font-size: 18px;
  margin-left: 7px;
}

.AAAAAAAAAAAAAAAAAAAAA:hover {
  font-weight: 700;
}

@media (max-width: 767px) {
  .appointment {
    margin-left: 0px;
    margin-top: 0px;
  }
  .AAAAA {
    margin-left: 0px;
    margin-top: 0px;
  }
}
