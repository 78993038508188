table {
  border-collapse: collapse;
  width: 98%;
}

th,
td {
  border: 1px solid #dbdbdb;
  padding: 8px;
}

th {
  background: rgba(235, 235, 235, 0.3);

  text-align: center;
  font-weight: 600;
}

td .name_of_lab {
  padding: 5px 0px 5px 10px;
  font-weight: 500;
}

.other_data_ofthe_table {
  text-align: center;
}

.ter {
  width: 40px;
}

.uspevaemost {
  margin-left: 350px;
  margin-top: -287px;
}

.my_progr {
  color: #263238;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  margin-bottom: 20px;
}

.acc_usp_usp {
  padding: 10px 0px;
  padding-right: 64px;
  padding-left: 10px;
  border: none;
  color: white;
  background-color: #eba14f;
  font-weight: 600;
  font-size: 16px;
}

.buttons_ter1,
.buttons_ter2,
.buttons_ter3 {
  width: 20%;
  height: 30px;
  text-align: center;
  border: 1px solid #dbdbdb;
  border-right: 2px solid #bcbcbc;
  background-color: #dbdbdb;
  color: white;
  user-select: none;
}

.sum_of {
  width: 35%;
}

.otchetnik {
  width: 30%;
}

.work_name {
  width: 31%;
}

.buttons_ter1 {
  border-radius: 25px 0px 0px 0px;

  border: 0px solid #e94a5b;
}

.buttons_ter2 {
  border-radius: 0px 0px 0px 0px;
  border: 0px solid #e94a5b;
}

.buttons_ter3 {
  border-right: 0px;
  border-radius: 0px 25px 0px 0px;
  border: 0px solid #e94a5b;
}

.active {
  background-color: #e94a5b;
}

.posesh {
  width: 20%;
}

.testovski {
  width: 18%;
}

@media (max-width: 767px) {
  .uspevaemost {
    margin-left: 0;
    margin-top: 10px;
  }
  .buttons_ter1 {
    width: 80px;
  }
  .buttons_ter2 {
    width: 100px;
  }
  .buttons_ter3 {
    width: 80px;
  }
  .some_data_in_table {
    font-size: 12px;
  }
  .acc_usp_usp {
    font-size: 12px;
    background-color: rgba(214, 214, 214, 0);
    color: black;
    /* margin-left: -23px; */
    border: 1px solid;
    padding-right: 76px;
  }
  td {
    font-size: 12px;
  }
  .my_progr {
    font-size: 20px;
  }
}
