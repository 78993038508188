header {
  display: flex;
  width: 100%;
  height: 70px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background-color: #fff;
}

h3 .mobile_lab {
  color: #fff;
}

.infochem_again_text {
  color: black;
}

/* .circle_nav {
  transition: all 0.2s ease;
}

.circle_nav:hover {
  transform: scale(1.1);
} */

.menu_acc {
  margin: 0 -80px;
  height: 110px;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu_light_acc {
  list-style-type: none;
  display: flex;
  justify-content: center;
  /* justify-content: space-between; */
}

.menu_item_acc {
  text-decoration: none;
  line-height: 100px;
  padding: 0px 20px;
  padding-top: 25px;
  display: block;
}

.logo_acc {
  width: 190%;
  /* height: 97px; */
}

.menu_link_acc {
  color: #000;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
}

.menu_link_bolder_acc {
  color: #342c41;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
  /* padding-left: 40px; */
}

.menu_link_bolder_infochem_acc {
  color: #342c41;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
  margin-left: 40px;
}

.bell {
  margin-right: 10px;
  cursor: pointer;
}

.bell:hover {
  -webkit-animation: ring 7s 0s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 7s 0s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 7s 0s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }
  1% {
    -webkit-transform: rotateZ(30deg);
  }
  3% {
    -webkit-transform: rotateZ(-28deg);
  }
  5% {
    -webkit-transform: rotateZ(34deg);
  }
  7% {
    -webkit-transform: rotateZ(-32deg);
  }
  9% {
    -webkit-transform: rotateZ(30deg);
  }
  11% {
    -webkit-transform: rotateZ(-28deg);
  }
  13% {
    -webkit-transform: rotateZ(26deg);
  }
  15% {
    -webkit-transform: rotateZ(-24deg);
  }
  17% {
    -webkit-transform: rotateZ(22deg);
  }
  19% {
    -webkit-transform: rotateZ(-20deg);
  }
  21% {
    -webkit-transform: rotateZ(18deg);
  }
  23% {
    -webkit-transform: rotateZ(-16deg);
  }
  25% {
    -webkit-transform: rotateZ(14deg);
  }
  27% {
    -webkit-transform: rotateZ(-12deg);
  }
  29% {
    -webkit-transform: rotateZ(10deg);
  }
  31% {
    -webkit-transform: rotateZ(-8deg);
  }
  33% {
    -webkit-transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
  }
  37% {
    -webkit-transform: rotateZ(2deg);
  }
  39% {
    -webkit-transform: rotateZ(-1deg);
  }
  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }
  1% {
    -moz-transform: rotate(30deg);
  }
  3% {
    -moz-transform: rotate(-28deg);
  }
  5% {
    -moz-transform: rotate(34deg);
  }
  7% {
    -moz-transform: rotate(-32deg);
  }
  9% {
    -moz-transform: rotate(30deg);
  }
  11% {
    -moz-transform: rotate(-28deg);
  }
  13% {
    -moz-transform: rotate(26deg);
  }
  15% {
    -moz-transform: rotate(-24deg);
  }
  17% {
    -moz-transform: rotate(22deg);
  }
  19% {
    -moz-transform: rotate(-20deg);
  }
  21% {
    -moz-transform: rotate(18deg);
  }
  23% {
    -moz-transform: rotate(-16deg);
  }
  25% {
    -moz-transform: rotate(14deg);
  }
  27% {
    -moz-transform: rotate(-12deg);
  }
  29% {
    -moz-transform: rotate(10deg);
  }
  31% {
    -moz-transform: rotate(-8deg);
  }
  33% {
    -moz-transform: rotate(6deg);
  }
  35% {
    -moz-transform: rotate(-4deg);
  }
  37% {
    -moz-transform: rotate(2deg);
  }
  39% {
    -moz-transform: rotate(-1deg);
  }
  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}

.line {
  margin-right: 12px;
}

@media screen and (max-width: 1400px) {
  .menu_item_acc .menu_link_acc {
    font-size: 12px;
  }
  .menu_link_bolder_infochem_acc {
    font-size: 14px;
  }
  .menu_item_registration_acc .menu_link_bolder_reg_acc {
    font-size: 15px;
    margin-left: -20px;
  }
  .menu_item_login_acc .menu_link_bolder_acc {
    font-size: 15px;
    margin-left: -40px;
  }
  .menu_light_acc {
    margin-right: 0px;
    margin-left: -20px;
  }
  .menu_acc {
    margin: 0;
  }
}

@media screen and (max-width: 1200px) {
  .menu_item_acc .menu_link_acc {
    font-size: 14px;
  }
  .menu_light_acc {
    margin-right: -50px;
    /* margin-left: -50px; */
  }
  .menu_item_acc .menu_link_bolder_infochem_acc {
    font-size: 16px;
    margin-left: -10px;
  }
  .menu_item_registration_acc .menu_link_bolder_reg_acc {
    font-size: 13px;
    margin-left: -50px;
  }
  .menu_item_login_acc .menu_link_bolder_acc {
    font-size: 13px;
    margin-left: -80px;
  }
  .logo_acc {
    width: 100px;
    margin-left: -45px;
  }
  .circle_nav {
    width: 40px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .menu_light_acc {
    margin-right: -120px;
    /* margin-left: -50px; */
  }
  .menu_item_acc .menu_link_bolder_infochem_acc {
    font-size: 12px;
    margin-left: -20px;
  }
  .menu_item_registration_acc .menu_link_bolder_reg_acc {
    font-size: 13px;
    margin-left: -50px;
  }
  .menu_item_login_acc .menu_link_bolder_acc {
    font-size: 13px;
    margin-left: -80px;
  }
  .logo_acc {
    width: 90px;
    margin-left: -45px;
  }

  .menu_light_acc .menu_item_acc .menu_link_acc {
    font-size: 12px;
    margin-left: -30px;
  }
  .circle_nav {
    padding: 5px;
  }
  .bell {
    padding: 5px;
  }
}

@media screen and (max-width: 767px) {
  .menu_light_acc {
    display: none;
  }
  .menu_light_acc .menu_item_acc .menu_link_acc {
    font-size: 21px;
    padding: 0;
    margin: 0;
  }
  .menu_light_acc .menu_item_acc {
    padding: 0;
    line-height: 60px;
    margin-left: -10px;
  }
  .menu_light_acc .menu_item_acc .menu_link_acc svg {
    display: none;
  }
  .circle_nav {
    display: none;
  }
  .arrow_nav {
    display: none;
  }
  .bell {
    display: none;
  }
  .line {
    display: none;
  }
  .hamburger_acc {
    display: block;
    position: absolute;
    left: 0px;
    top: 18px;
    margin-top: 1%;
    transform: translateY(-50%);
    width: 40px;
    padding-left: 10px;
  }
  .hamburger_acc span {
    display: block;
    height: 4px;
    width: 100%;
    background-color: black;
    margin-bottom: 4px;
    transition: 0.2s all;
  }
  .hamburger_acc_active {
    top: 2%;
    margin-top: 1%;
  }
  .hamburger_acc_active span {
    margin-bottom: -4px;
  }
  .hamburger_acc_active span:nth-child(1) {
    transform: translateY(3px) rotate(-45deg);
  }
  .hamburger_acc_active span:nth-child(2) {
    display: none;
  }
  .hamburger_acc_active span:nth-child(3) {
    transform: translateY(3px) rotate(45deg);
  }

  .menu_item_acc .menu_link_bolder_infochem_acc {
    font-size: 16px;
    margin-left: -20px;
    position: absolute;
    margin-top: -75px;
    display: none;
  }
}

@media (max-width: 550px) {
  .infochem_again_text {
    left: 27%;
  }
}

@media (max-width: 400px) {
  .infochem_again_text {
    left: 23%;
  }
  .rect {
    width: 93vw;
  }
}

@media (max-width: 345px) {
  .infochem_again_text {
    left: 20%;
  }
  .login_button_2 {
    font-size: 14px;
  }
}
