.students_groups_list {
  color: hsl(200, 19%, 18%);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  margin-left: 340px;
  margin-top: -235px;
}

.listing_balls {
  font-size: 12;
}

.acc_stud_balling {
  padding: 10px 0px;
  padding-right: 107px;
  padding-left: 10px;
  border: none;
  color: white;
  background-color: #eba14f;
  font-weight: 600;
}

@media (max-width: 767px) {
  .students_groups_list {
    color: hsl(200, 19%, 18%);
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    margin-left: 0;
    margin-top: -0;
  }
  .acc_stud_balling {
    font-size: 12px;
    background-color: rgba(214, 214, 214, 0);
    border: 1px solid;
    color: black;
    padding-right: 105px;
    margin-left: 0px;
  }
}
