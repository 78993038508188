.mylabs {
  margin-left: 350px;
  margin-top: -287px;
}

.my_laboratonski {
  color: #263238;
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  /* margin-bottom: 20px; */
}

.my_laboratonski_text {
  color: #263238;
  font-family: Montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 20px;
}

.mylabs .progress_ball,
.mylabs .danba {
  display: inline-block;
}

.corcle {
  /* background-color: #eba14f; */
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  padding-bottom: 2px;
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 1px;
  margin-right: 6px;
}

.corcle_item {
  list-style-type: none;
  margin-left: -35px;
  margin-bottom: 15px;
  margin-top: -5px;
}

.danba {
  display: block;
  height: 1px;
  width: 6px;
  background-color: black;
  margin-bottom: 7px;
  margin-left: -5.9px;
  transition: 0.2s all;
}

.bowler {
  margin-top: 100px;
}

.progress_ball {
  user-select: none;
}

.progress_ball span {
  cursor: pointer;
}

.progress_ball span:nth-child(1) {
  transform: translateY(3px) rotate(45deg);
  margin-left: 4px;
}

.progress_ball span:nth-child(2) {
  transform: translateY(3.3px) rotate(-45deg);
  margin-left: -5.9px;
}

.progress_ball_active span:nth-child(1) {
  transform: translateY(3px) rotate(-45deg);
  margin-left: 4px;
}

.progress_ball_active span:nth-child(2) {
  transform: translateY(2.9px) rotate(45deg);
}

.elems_of_prog_active {
  display: none;
}

.corcle_active {
  background: #eba14f;
  border: 1px solid #eba14f;
}

.acc_lab_lab {
  display: flex;
  padding: 10px 0px;
  padding-right: 18px;
  padding-left: 10px;
  border: none;
  color: white;
  background-color: #eba14f;
  font-weight: 600;
}

.acc_doc_lab_lab {
  padding: 10px 0px;
  padding-right: 58px;
  padding-left: 10px;
  border: none;
  color: black;
  background-color: white;
  font-weight: 600;
  font-size: 16px;
}

.acc_doc_lab_lab:hover {
  background-color: #eba14f;
  color: white;
}

.line_betw_c {
  display: block;
  width: 200px;
  height: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
}
.progress_bar_line .circle_svg_co,
.progress_bar_line .line_betw_c {
  display: inline-block;
}

.circle_svg_co {
  width: 50px;
  height: 50px;
  border: 2px solid rgba(190, 190, 190, 0);
  border-radius: 100%;
  padding-top: 8px;
  padding-left: 18px;
  /* background-color: #53df22; */
}

.s {
  margin-left: -1px;
}

.t {
  margin-left: -1px;
}

.fr {
  margin-left: -3px;
}

.circle_svg_co h1 {
  font-size: 26px;
}

.progress_bar_line {
  margin-left: -35px;
  margin-top: 40px;
}

.green {
  background-color: #53df22;
}
.dark-green {
  background-color: #9ac71b;
}
.yellow {
  background-color: #ffe500;
}
.orange {
  background-color: #fb9b30;
}
.blue {
  background-color: #8abfff;
}
.red {
  background-color: #e02828;
}
.white {
  background-color: #ebebeb;
}

.my_svg_3_span {
  margin-left: -12px;
}

.fir17 {
  --color2: #9ac71b;
  --color1: #53df22;
  background: linear-gradient(to right, var(--color1) 0%, var(--color2) 100%);
}

.fir16 {
  --color1: #9ac71b;
  --color2: #53df22;
  background: linear-gradient(to right, var(--color1) 0%, var(--color2) 100%);
}

.fir15 {
  --color2: #53df22;
  --color1: #fb9b30;
  background: linear-gradient(to right, var(--color1) 0%, var(--color2) 100%);
}

.fir14 {
  --color1: #fb9b30;
  --color2: #53df22;
  background: linear-gradient(to right, var(--color1) 0%, var(--color2) 100%);
}
.fir13 {
  --color2: #fb9b30;
  --color1: #9ac71b;
  background: linear-gradient(to right, var(--color1) 0%, var(--color2) 100%);
}
.fir12 {
  --color1: #fb9b30;
  --color2: #9ac71b;
  background: linear-gradient(to right, var(--color1) 0%, var(--color2) 100%);
}
.fir11 {
  --color1: #53df22;
  --color2: #ebebeb;
  background: linear-gradient(to right, var(--color1) 0%, var(--color2) 100%);
}
.fir10 {
  --color1: #ebebeb;
  --color2: #53df22;
  background: linear-gradient(to right, var(--color1) 0%, var(--color2) 100%);
}
.fir9 {
  --color1: #9ac71b;
  --color2: #ebebeb;
  background: linear-gradient(to right, var(--color1) 0%, var(--color2) 100%);
}
.fir8 {
  --color2: #9ac71b;
  --color1: #ebebeb;
  background: linear-gradient(to right, var(--color1) 0%, var(--color2) 100%);
}
.fir7 {
  --color1: #fb9b30;
  --color2: #ebebeb;
  background: linear-gradient(to right, var(--color1) 0%, var(--color2) 100%);
}
.fir6 {
  --color2: #fb9b30;
  --color1: #ebebeb;
  background: linear-gradient(to right, var(--color1) 0%, var(--color2) 100%);
}
/* .fir6 {
  --color1: #fb9b30;
  --color2: #ebebeb;
  background: linear-gradient(to right, var(--color1) 0%, var(--color2) 100%);
} */
/* .fir5 {
  --color1: #fb9b30;
  --color2: #ebebeb;
  background: linear-gradient(to right, var(--color1) 0%, var(--color2) 100%);
} */

.fir3 {
  --color1: #53df22;
  --color2: #53df22;
  background: linear-gradient(to right, var(--color1) 0%, var(--color2) 100%);
}

.fir4 {
  --color1: #ebebeb;
  --color2: #ebebeb;
  background: linear-gradient(to right, var(--color1) 0%, var(--color2) 100%);
}
.fir1 {
  --color1: #9ac71b;
  --color2: #9ac71b;
  background: linear-gradient(to right, var(--color1) 0%, var(--color2) 100%);
}

.fir2 {
  --color1: #fb9b30;
  --color2: #fb9b30;
  background: linear-gradient(to right, var(--color1) 0%, var(--color2) 100%);
}

/* 
.line_betw_c .fir3 {
  --color1: #fb9b30;
  --color2: #fb9b30;
  background: linear-gradient(to right, var(--color1) 0%, var(--color2) 100%);
} */

/* .line_betw_c {
  --color1: #53df22;
  --color2: #9ac71b;
  background: linear-gradient(to right, var(--color1) 0%, var(--color2) 100%);
} */

.ot4et_button {
  color: #fff;
  background-color: #bebebe;
  border: 1px solid #bebebe;
  border-radius: 30px;
  padding: 5px 40px;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin-top: 30px;
  margin-left: -35px;
  transition: all 0.2s ease;
}

.ot4et_button:hover {
  background-color: #939393;
}

.my_labs p {
  cursor: progress;
}

.file_input_label {
  color: #fff;
  background-color: #bebebe;
  border: 1px solid #bebebe;

  border-radius: 30px;
  padding: 5px 40px;
  text-align: center;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin-top: 30px;
  margin-left: -35px;
  transition: all 0.2s ease;
  margin-right: 100px;
}

.file_input_label:hover {
  background-color: #939393;
}

@media (max-width: 767px) {
  .mylabs {
    margin-left: 0;
    margin-top: 10px;
  }
  .acc_lab_lab {
    font-size: 12px;
    background-color: rgba(214, 214, 214, 0);
    padding-right: 0;
    /* margin-left: -53px; */
    border: 1px solid;
    padding-right: 42px;
    color: black;
  }
  .acc_doc_lab_lab {
    font-size: 12px;
    background-color: rgba(214, 214, 214, 0);
    color: black;
    /* margin-left: -23px; */
    border: 1px solid;
    padding-right: 73px;
  }
  .my_laboratonski {
    font-size: 20px;
    padding-bottom: 20px;
  }
  .my_laboratonski_text {
    font-size: 16px;
  }
  .progress_ball {
    font-size: 14px;
  }
  .corcle_item {
    font-size: 14px;
  }
  .line_betw_c {
    width: 10%;
  }
  .progress_bar_line {
    margin: 0;
    margin-left: -20px;
  }
  .file_input {
    margin-left: -40px;
  }
}
