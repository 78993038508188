.all_info {
  margin-left: 400px;
  margin-top: -230px;
}

.billings {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin-top: 50px;
}

.test_results_button_yeah {
  padding: 2px 10px;
  /* padding-right: 64px; */
  padding-left: 10px;
  border: 1px solid;
  color: black;
  background-color: white;
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
}

.test_results_button_yeah:hover {
  background-color: #eba14f;
  color: white;
}

.save_button_for_admin {
  padding: 2px 10px;
  /* padding-right: 64px; */
  padding-left: 10px;
  border: 1px solid;
  color: black;
  background-color: white;
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
  margin-left: 15px;
}

.save_button_for_labs_stud {
  padding: 5px 20px;
  /* padding-right: 64px; */
  /* padding-left: 10px; */
  border: 1px solid;
  color: black;
  background-color: white;
  font-weight: 600;
  font-size: 18px;
  /* margin-top: 10px; */
  /* margin-left: 15px; */
}
.save_button_for_labs_stud:hover {
  background-color: #eba14f;
  color: white;
}

.name_of_lab_stud {
  font-weight: 700;
  font-size: 24px;
}

.save_button_for_admin:hover {
  background-color: #eba14f;
  color: white;
}

.balldwin {
  list-style-type: none;
}

.labs_info {
  border: 1px solid black;
  padding: 10px;
  margin-top: 30px;
}

.balling_test_scores {
  list-style-type: none;
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .all_info {
    margin-left: 0;
    margin-top: -20px;
  }
  .save_button_for_admin {
    margin-left: 0;
  }
  .header_stud_det {
    margin-top: 70px;
  }
}
