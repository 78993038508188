.acc_header {
  color: #263238;
  font-family: Montserrat;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.8px;
  padding-top: 50px;
  padding-bottom: 40px;
  max-width: 90%;
}

.status {
  font-size: 16px;
  position: absolute;
  bottom: 0;
  transform: translateX(15px);
}

.acc_main_section {
  height: 110vh;
}

.acc_list {
  display: flex;
  flex-direction: column;
  width: 195px;
  padding: 0;
}

.acc_list button {
  margin-bottom: 10px;
  float: left;
  position: relative;
}

.acc_acc {
  display: flex;
  padding: 10px 0px;
  padding-right: 114px;
  padding-left: 10px;
  border: none;
  color: white;
  background-color: #eba14f;
  font-weight: 600;
}

.rect {
  background-color: #edeeef;
  border: 0px solid;
  height: 120px;
  width: 994px;
  position: absolute;
  margin-top: -287px;
  margin-left: 350px;
  border-radius: 85px 0 0 0;
}

/* .circle {
  background-color: #fff;
  width: 110px;
  height: 110px;
  border-radius: 100px;
  margin-left: -65px;
  margin-top: -200px;
} */

.button {
  transition: all 0.2 ease;
}

.acc_usp {
  padding: 10px 0px;
  padding-right: 64px;
  padding-left: 10px;
  border: none;
  color: black;
  background-color: white;
  font-weight: 600;
  font-size: 16px;
}

.acc_usp:hover {
  background-color: #eba14f;
  color: white;
}

.acc_test {
  padding: 10px 0px;
  padding-right: 134px;
  padding-left: 10px;
  border: none;
  color: black;
  background-color: white;
  font-weight: 600;
  font-size: 16px;
  /* margin-top: -13px; */
}

.acc_test:hover {
  background-color: #eba14f;
  color: white;
}

.acc_lab {
  padding: 10px 0px;
  padding-right: 18px;
  padding-left: 10px;
  border: none;
  color: black;
  background-color: white;
  font-weight: 600;
  font-size: 16px;
  /* padding-top: -100px; */
}

.acc_lab:hover {
  background-color: #eba14f;
  color: white;
}

.acc_doc {
  padding: 10px 0px;
  padding-right: 58px;
  padding-left: 10px;
  border: none;
  color: black;
  background-color: white;
  font-weight: 600;
  font-size: 16px;
}

.acc_doc:hover {
  background-color: #eba14f;
  color: white;
}

.acc_photo {
  height: 120px;
  width: 120px;
  /* height: 100%; */
  border: 3.4px solid white;
  border-radius: 100%;
  /* margin-left: -66px; */
  /* margin-top: -580px; */
  /* margin-bottom: 200px; */
  margin-left: 43px;
  margin-top: 60px;
}

.acc_phone_num {
  color: #263238;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

.acc_mail {
  color: #263238;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 255%;
}

.form .acc_input-box {
  margin-top: 10px;
  padding-right: 25px;
  padding-top: 10px;
  /* padding-bottom: -150px; */
}

.form :where(.acc_input-box input, .select-box) {
  position: relative;
  height: 45px;
  width: 70%;
  outline: none;
  font-size: 1rem;
  color: #707070;
  margin-top: -100px;
  border: 1px solid #000;
  /* border-radius: 100px; */
  padding: 0 10px;
}

/* .form .acc_mail {
  margin-top: 10px;
  padding-right: 25px;
  padding-top: 10px;
} */

/* .name_place svg {
  display: none;
} */

.name_place {
  position: absolute;
  margin-top: -55px;
  margin-left: 180px;
  color: #263238;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.54px;
}

.ISU {
  color: #263238;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.05px;
  margin-left: 180px;
  margin-top: -25px;
}

.social {
  color: #263238;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 18.75px */
  display: flex;
  width: 200px;
  margin-left: 500px;
  margin-top: -165px;
}

.social_urls {
  padding-top: 10px;
  margin-left: 500px;
  width: 300px;
  margin-bottom: 10px;
}

.url1 {
  color: #263238;
  /* font-family: Montserrat; */
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline;
  /* padding-top: -100px; */
  margin-top: -27px;
  margin-left: 10px;
}

.test_test {
  margin-top: -23px;
}

.phone_number_acc {
  font-weight: 400;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  border: 1px solid #263238;
  padding: 15px 10px;
  /* margin-right: 300px; */
}

.phone_balls_yk {
  width: 300px;
  margin-top: 50px;
}

.phone_balls_yk2 {
  width: 300px;
}

.rich_scholar {
  margin-top: 70px;
  margin-bottom: 10px;
}

.mail_balls_yk {
  width: 300px;
  margin-bottom: 50px;
}

.email_acc_text {
  font-weight: 400;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  border: 1px solid #263238;
  padding: 15px 10px;
  /* margin-right: 100px; */
  margin-top: -12px;
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .rect {
    width: 750px;
  }
  /* .name_place svg {
    display: none;
  } */
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .rect {
    width: 550px;
  }
  .social {
    margin-left: 400px;
  }
  .url1 {
    font-size: 12px;
    /* margin-left: 35px; */
  }
  .social_urls {
    margin-left: 400px;
    width: 160px;
  }
  /* .name_place svg {
    display: none;
  } */
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .status {
    font-size: 10px;
    transform: translateX(10px);
  }

  .rect {
    width: 400px;
    height: 70px;
    margin-top: -240px;
    margin-left: 260px;
  }
  /* .name_place svg {
    display: none;
  } */
  .phone_number_acc {
    width: 150px;
    /* height: 10px; */
    padding: 5px 10px;
  }

  .email_acc_text {
    width: 150px;
    /* height: 10px; */
    padding: 5px 10px;
  }
  .social {
    margin-left: 180px;
    margin-top: -133px;
  }
  .url1 {
    font-size: 12px;
    margin-left: 35px;
  }
  .social_urls {
    margin-left: 180px;
  }
  .acc_photo {
    height: 120px;
    width: 70px;
    margin-top: 35px;
    margin-left: 20px;
  }
  .acc_header {
    font-size: 26px;
  }
  .acc_acc {
    font-size: 10px;
    padding-right: 95px;
  }
  .acc_usp {
    font-size: 10px;
  }
  .acc_lab {
    font-size: 10px;
    padding-left: -95px;
    padding-right: 35px;
  }
  .acc_test {
    font-size: 10px;
    padding-right: 107px;
  }
  .acc_doc {
    font-size: 10px;
  }
  .acc_list {
    width: 150px;
  }
  .name_place {
    font-size: 10px;
    margin-top: -35px;
    margin-left: 100px;
  }
  .name_place svg {
    width: 12px;
  }
  .ISU {
    font-size: 8px;
    margin-left: 100px;
    margin-top: -15px;
  }
  .social_urls svg {
    height: 30px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .rect {
    width: 400px;
    height: 70px;
    margin-top: -240px;
    margin-left: 260px;
  }
  /* .name_place svg {
    display: none;
  } */
  .phone_number_acc {
    width: 150px;
    /* height: 10px; */
    padding: 5px 10px;
  }

  .email_acc_text {
    width: 150px;
    /* height: 10px; */
    padding: 5px 10px;
  }
  .social {
    margin-left: 180px;
    margin-top: -133px;
  }
  .url1 {
    font-size: 12px;
    margin-left: 35px;
  }
  .social_urls {
    margin-left: 180px;
  }
  .acc_photo {
    height: 100%;
    margin-top: 35px;
    margin-left: 20px;
  }
  .acc_header {
    font-size: 26px;
  }
  .acc_acc {
    font-size: 10px;
  }
  .acc_usp {
    font-size: 10px;
  }
  .acc_groups {
    font-size: 10px;
  }
  .acc_lab {
    font-size: 10px;
  }
  .acc_test {
    font-size: 10px;
  }
  .acc_doc {
    font-size: 10px;
  }
  .acc_list {
    width: 150px;
  }
  .name_place {
    font-size: 10px;
    margin-top: -35px;
    margin-left: 100px;
  }
  .name_place svg {
    width: 12px;
  }
  .ISU {
    font-size: 8px;
    margin-left: 100px;
    margin-top: -15px;
  }
  .social_urls svg {
    height: 30px;
  }
}

@media screen and (max-width: 767px) {
  .status {
    font-size: 10px;
    transform: translateX(0px);
  }
  .acc_list {
    position: fixed;
    top: 0;
    right: -100%;
    width: 60%;
    height: 100%;
    padding: 10px 10px;
    flex-direction: column;
    background-color: rgba(214, 214, 214, 0.9);
    /* align-items: center; */
    justify-content: center;
    transition: 0.6s all;
    z-index: 100;
    margin: 0;
  }

  .acc_list_active {
    right: 0; /* При активации меню, сдвигаем его вправо на 0% */
    align-items: start;
  }
  .acc_acc {
    display: flex;
    border: 1px solid;
    color: black;
    background-color: rgba(214, 214, 214, 0);
    font-weight: 600;
    font-size: 12px;
  }

  .acc_usp {
    border: 1px solid;
    color: black;
    background-color: rgba(214, 214, 214, 0);
    font-weight: 600;
    font-size: 12px;
    padding-right: 77px;
  }

  .acc_acc:hover {
    font-size: 12px;
    background-color: rgba(214, 214, 214, 0);
    color: rgb(92, 92, 92);
  }

  .acc_usp:hover {
    font-size: 12px;
    background-color: rgba(214, 214, 214, 0);
    color: rgb(92, 92, 92);
  }

  .acc_lab:hover {
    font-size: 12px;
    background-color: rgba(214, 214, 214, 0);
    color: rgb(92, 92, 92);
  }

  .acc_doc:hover {
    font-size: 12px;
    background-color: rgba(214, 214, 214, 0);
    color: rgb(92, 92, 92);
  }

  .acc_test:hover {
    font-size: 12px;
    background-color: rgba(214, 214, 214, 0);
    color: rgb(92, 92, 92);
  }

  .acc_lab {
    font-size: 12px;
    background-color: rgba(214, 214, 214, 0);
    padding-right: 0;
    /* margin-left: -53px; */
    border: 1px solid;
    padding-right: 42px;
  }
  .acc_test {
    font-size: 12px;
    background-color: rgba(214, 214, 214, 0);
    padding-right: 129px;
    border: 1px solid;
  }
  .acc_doc {
    font-size: 12px;
    background-color: rgba(214, 214, 214, 0);
    border: 1px solid;
    padding-right: 72px;
  }
  .acc_test_test {
    font-size: 12px;
    background-color: rgba(214, 214, 214, 0);
    padding-right: 0;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .status {
    font-size: 10px;
    transform: translateX(0px);
  }
  .rect {
    width: 500px;
    height: 80px;
    /* margin-top: -240px; */
    padding: 0;
    margin: 0;
    /* margin-left: 260px; */
  }
  /* .name_place svg {
    display: none;
  } */
  .phone_number_acc {
    width: 150px;
    /* height: 10px; */
    padding: 5px 10px;
  }

  .email_acc_text {
    width: 150px;
    /* height: 10px; */
    padding: 5px 10px;
  }
  .social {
    margin-left: 220px;
    margin-top: -128px;
  }
  .url1 {
    font-size: 12px;
    margin-left: 35px;
  }
  .social_urls {
    margin-left: 220px;
  }
  .acc_photo {
    height: 80px;
    /* height: 100%; */
    width: 80px;
    margin-top: 35px;
    margin-left: 20px;
  }
  .acc_header {
    font-size: 16px;
    letter-spacing: 2px;
  }
  /* .acc_acc {
    font-size: 10px;
  }
  .acc_usp {
    font-size: 10px;
  }
  .acc_lab {
    font-size: 10px;
  }
  .acc_test {
    font-size: 10px;
  }
  .acc_doc {
    font-size: 10px;
  }
  .acc_list {
    width: 150px;
    display: none;
  } */
  .name_place {
    font-size: 10px;
    margin-top: -35px;
    margin-left: 100px;
  }
  .name_place svg {
    width: 12px;
  }
  .ISU {
    font-size: 8px;
    margin-left: 100px;
    margin-top: -15px;
  }
  .social_urls svg {
    height: 30px;
  }
  /* .acc_list {
    width: 100px;
  } */
  .logo_acc {
    display: none;
  }
  .acc_header {
    padding: 15px 0;
  }
}

@media (min-width: 300px) and (max-width: 575.98px) {
  .status {
    font-size: 16px;
    transform: translate(10px, -10px);
  }
  .rect {
    margin: 0;
    height: 100px;
    width: 100%;
  }
  /* .name_place svg {
    display: none;
  } */
  .phone_number_acc {
    width: 270px;
    /* height: 10px; */
    padding: 14px 10px;
  }

  .email_acc_text {
    width: 270px;
    /* height: 10px; */
    padding: 14px 10px;
  }
  .social {
    margin-left: 0px;
    margin-top: 40px;
  }
  .url1 {
    font-size: 12px;
    margin-left: 10px;
  }
  .social_urls {
    margin-left: 0px;
  }
  .acc_photo {
    height: 120%;
    margin-top: 50px;
    margin-left: 20px;
  }
  .acc_header {
    font-size: 25px;
    letter-spacing: 2px;
    padding: 10px 0;
  }
  /* .acc_acc {
    font-size: 10px;
    display: none;
  } */
  /* .acc_usp {
    font-size: 10px;
    display: none;
  }
  .acc_lab {
    font-size: 10px;
    display: none;
  }
  .acc_test {
    font-size: 10px;
    display: none;
  }
  .acc_doc {
    font-size: 10px;
    display: none;
  } */
  /* .acc_list {
    width: 150px;
    display: none;
  } */
  .name_place {
    font-size: 18px;
    margin-top: -70px;
    margin-left: 150px;
    letter-spacing: 0px;
    width: 147px;
  }
  .name_place svg {
    width: 20px;
  }
  .ISU {
    font-size: 14px;
    margin: 0;
    margin-left: 150px;
  }
  .social_urls svg {
    height: 30px;
  }

  .logo_acc {
    display: none;
  }
}

@media (max-width: 767px) {
  .mail_balls_yk input {
    padding-right: 30%;
  }
}
