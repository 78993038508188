body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll; /* Включает вертикальную полосу прокрутки */
  scrollbar-width: none; /* Скрывает стандартную полосу прокрутки в Firefox */
  -ms-overflow-style: none;
}

body::-webkit-scrollbar {
  display: none; /* Скрывает стандартную полосу прокрутки в Chrome и Safari */
}

code {
  font-family: "Montserrat", sans-serif;
}
