.djobir {
  font-size: 18px;
  padding: 8px 30px;
  margin-left: 0px;
  border: none;
  color: white;
  background-color: #eba14f;
  font-weight: 600;
  transition: all ease 0.2s;
}

.pop_up_group {
  display: none;
}

.pop_up_group_active {
  display: block;
  position: fixed;
  top: 50%;
  left: 54%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding-top: 70px;
  padding-bottom: 70px;
  padding-right: 150px;
  padding-left: 150px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

.enter_group_name {
  color: #000000;

  font-family: Montserrat;
  font-size: 21.522px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.group-name-input {
  width: 100%;
  margin-top: 10px;
  border: 1px solid var(--Dark-gray, #bebebe);
  border-radius: 7px;
  color: var(--Dark-gray, #000000);
  padding-left: 10px;
}

.save-group-button {
  display: inline-flex;
  padding: 10px 33px 10px 33px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: #eba14f;
  color: #fff;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.05px;
  border: 0px;
  margin-top: 10px;
}

.ul_ulnik {
  column-count: 2; /* Разбиваем список на две колонки */
}

.links_balling_balls {
  break-inside: avoid; /* Предотвращаем разрывы студентов между колонками */
  margin-bottom: 10px; /* Добавляем отступ между студентами */
}

@media (max-width: 767px) {
  .djobir {
    margin-left: 0px;
    margin-top: 20px;
  }
}
