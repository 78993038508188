.all_studs {
  margin-left: 450px;
  margin-top: -230px;
  margin-right: 120px;
}
.ul_ulnik {
  list-style: none;
}

.links_balling_balls {
  font-weight: 600;
  margin-left: -30px;
  margin-bottom: 15px;
}

.links_balling_balls a {
  text-decoration: none;
  color: rgb(75, 75, 75);
}

.links_balling_balls a:hover {
  color: black;
}

@media (max-width: 1400px) {
  .ul_ulnik {
    display: flex;
    flex-direction: column;
    align-items: center; /* Центрирование элементов по горизонтали */
  }

  .links_balling_balls {
    width: 100%; /* Занимает всю ширину родительского элемента */
    text-align: start;
    padding: 0px; /* Добавление отступов для лучшего визуального восприятия */
  }
}

.stud_list_for_grrrr {
  margin-right: 30px;
  /* margin-bottom: 100px; */
}

.to_grouping {
  padding: 2px 10px;
  /* padding-right: 64px; */
  padding-left: 10px;
  border: 1px solid;
  color: black;
  background-color: white;
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
  padding: 10px;
  /* margin-left: 20%; */
}

.to_grouping:hover {
  background-color: #eba14f;
  color: white;
}

.create_da_group {
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 20px;
  /* letter-spacing: 1.05px; */
}

@media screen and (min-width: 1400px) and (max-width: 2400px) {
  .all_studs {
    margin-left: 33%;
    margin-right: 0;
  }
  .search_labs {
    max-width: 70%;
  }
}

@media (max-width: 767px) {
  .all_studs {
    margin-left: 0;
    margin-top: 0;
    margin-right: 0;
  }
}
