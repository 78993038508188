.acc_doc_test {
  padding: 10px 0px;
  padding-right: 58px;
  padding-left: 10px;
  border: none;
  color: #fff;
  background-color: #eba14f;
  font-weight: 600;
  font-size: 16px;
}

.labs_links {
  cursor: pointer;
}

.acc_doc_lab {
  padding: 10px 0px;
  padding-right: 58px;
  padding-left: 10px;
  border: none;
  color: #fff;
  background-color: #eba14f;
  font-weight: 600;
  font-size: 16px;
}

.acc_acc_lab {
  padding: 10px 0px;
  padding-right: 114px;
  padding-left: 10px;
  border: none;
  color: black;
  background-color: #fff;
  font-weight: 600;
}

.acc_acc_lab:hover {
  background-color: #eba14f;
  color: white;
}

.acc_test_labs {
  padding: 10px 0px;
  padding-right: 134px;
  padding-left: 10px;
  border: none;
  color: black;
  background-color: #fff;
  font-weight: 600;
}

.acc_test_labs:hover {
  background-color: #eba14f;
  color: white;
}

.colbs {
  margin-top: -20.5%;
  margin-left: 53%;
  /* margin-right: -20%; */
  width: 50%;
}

@media (min-width: 767px) and (max-width: 991.98px) {
  .colbs {
    margin-top: -13.5%;
    margin-left: 53%;
    /* margin-right: -20%; */
    width: 50%;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .colbs {
    margin-top: -20.5%;
    margin-left: 32%;
    /* margin-right: -20%; */
    width: 70%;
  }
}
@media (min-width: 300px) and (max-width: 575.98px) {
  .colbs {
    margin-top: -0.5%;
    margin-left: 23%;
    /* margin-right: -20%; */
    width: 70%;
  }
}
