.tbvideo_link {
  text-decoration: none;
  color: black;
  font-size: 20px;
}

.tbvideo_link:hover {
  font-weight: 600;
}

.tbvideos {
  display: flex;
  margin-left: 190px;
  margin-top: 50px;
  flex-direction: column;
}
