.search_labs {
  width: 85%;
  padding-left: 20px;
  border: 1px solid #bebebe;
  border-radius: 10px 10px 10px 10px;
}
.all_labs {
  margin-left: 350px;
  margin-top: -285px;
}

.custom-column {
  margin-right: 70px;
}

.inor {
  text-decoration: none;
  color: black;
}

.search_header {
  font-weight: 700;
  letter-spacing: 0px;
  font-size: 28px;
  padding-bottom: 15px;
}
.search_find {
  color: #fff;
  border: none;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: #eba14f;
  padding: 5.7px 40px 5px 40px;
  margin-left: -4px;
  border-radius: 0 10px 10px 0;
}

.search_labs {
  margin-bottom: 20px;
}

.link_to_lab {
  font-weight: 600;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.8);
}

.link_to_lab:hover {
  color: black;
}

@media (max-width: 767px) {
  .acc_acc_lab {
    display: flex;
    border: 1px solid;
    color: black;
    background-color: rgba(214, 214, 214, 0);
    font-weight: 600;
    font-size: 12px;
  }
  .acc_doc_lab {
    font-size: 12px;
    background-color: rgba(214, 214, 214, 0);
    border: 1px solid;
    padding-right: 72px;
    color: black;
  }
  .all_labs {
    margin-left: 0;
    margin-top: 0;
  }
}
