.main_section {
  height: 700px;
  background-image: url("/public/img/main_bg.png");
  width: 100%;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
}

.main_text {
  color: #fff;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 69.5px; /* 132.5% */
  letter-spacing: 3.3px;
  text-transform: uppercase;
  padding-top: 260px;
}

.sec_text {
  color: #fff;
  padding-top: 30px;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; /* 84.375% */
}

.join_button {
  display: flex;
  align-items: flex-start;
  padding: 7px 40px;
  color: rgba(0, 0, 0, 0.7);
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 166.667% */
  background-color: #fad27c;
  border: 1px solid #fad27c;
  margin-top: 40px;
  transition: all 0.2s ease;
}

.join_button .join_button_span {
  text-decoration: none;
}

.join_button:hover {
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid;
}

.row {
  position: relative;
}

.ITMO {
  z-index: 100;
  width: 555px;
  height: 180.785px;
  flex-shrink: 0;
  position: absolute;
  margin-top: -130px;
  margin-left: 60%;
}

@media screen and (max-width: 1400px) {
  .ITMO {
    margin-left: 51%;
  }
  .sec_text {
    /* font-size: 29px; */
    line-height: 30px;
  }
}

@media screen and (max-width: 1200px) {
  .ITMO {
    width: 455px;
    height: 140.785px;
    margin-left: 54%;
  }
  .main_text {
    font-size: 38px;
  }
  .sec_text {
    font-size: 29px;
    line-height: 30px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .ITMO {
    width: 355px;
    height: 100.785px;
    margin-top: -86px;
  }
  .main_text {
    font-size: 28px;
    line-height: 40px;
    padding-top: 150px;
  }
  .sec_text {
    font-size: 19px;
    line-height: 30px;
  }
  .main_section {
    height: 500px;
  }
}
.join_link_button {
  text-decoration: none;
}

@media (min-width: 425px) and (max-width: 450px) {
  /* .main_section {
    height: 500px;
  } */
}
