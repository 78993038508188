.test_section {
  margin-left: 30%;
  margin-right: 14%;
  margin-top: -380px;
}

.row_test {
  padding-bottom: 10px;
  width: 110%;
}

.input_test {
  margin-right: 10px;
}

.answer_balls {
  width: 90%;

  /* padding-bottom: 10px; */
}

.test_image_tb {
  margin-top: -10px;
  margin-bottom: 24px;
}

.test_button_tb {
  padding: 10px 50px;
  margin-top: 100px;
  border-radius: 30px;
  background: #b9c4cc;
  border: 1px;
  color: white;
  font-weight: 600;
  transition: all 0.2s ease;
  margin-bottom: 20px;
  margin-left: 200px;
}

.test_button_tb:hover {
  background: #848484;
}

.test_title {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding-bottom: 20px;
  padding-top: 80px;
  line-height: 30px;
}
