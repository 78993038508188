.acc_labs {
  padding: 11px 0px;
  padding-left: 6px;
  border: none;
  color: black;
  background-color: white;
  font-weight: 600;
  font-size: 15px;
}

.acc_labs:hover {
  background-color: #eba14f;
  color: white;
}

.acc_groups {
  padding: 10px 0px;
  padding-left: 10px;
  padding-right: 85px;
  border: none;
  color: black;
  background-color: white;
  font-weight: 600;
  font-size: 15px;
}

.acc_groups:hover {
  background-color: #eba14f;
  color: white;
}

.acc_stud {
  padding: 10px 0px;
  padding-left: 10px;
  padding-right: 107px;
  border: none;
  color: black;
  background-color: white;
  font-weight: 600;
  font-size: 15px;
}

.acc_stud:hover {
  background-color: #eba14f;
  color: white;
}

.rect_prof {
  margin-top: -228px;
}

.usp_lab {
  padding-right: 57px;
}

@media (max-width: 992px) {
  .acc_groups {
    padding: 10px 0px;
    padding-left: 10px;
    /* padding-right: 85px; */
    border: none;
    color: black;
    background-color: white;
    font-weight: 600;
    font-size: 10px;
  }
}

@media (min-width: 300px) and (max-width: 767px) {
  .rect_prof {
    margin: 0;
  }
  .acc_groups {
    display: flex;
    border: 1px solid;
    color: black;
    background-color: rgba(255, 255, 255, 0);
    font-weight: 600;
    font-size: 12px;
    margin-left: 0px;
    padding-right: 89px;
  }
  .acc_stud {
    display: flex;
    border: 1px solid;
    color: black;
    background-color: rgba(255, 255, 255, 0);
    font-weight: 600;
    font-size: 12px;
    /* margin-left: -10px; */
    padding-right: 105px;
  }
  .usp_lab {
    display: flex;
    border: 1px solid;
    color: black;
    background-color: rgba(255, 255, 255, 0);
    font-weight: 600;
    font-size: 12px;
    padding-right: 71px;
    /* margin-left: 1px; */
  }
  .acc_groups:hover {
    background-color: rgba(214, 214, 214, 0);
    color: rgb(92, 92, 92);
  }
  .acc_stud:hover {
    background-color: rgba(214, 214, 214, 0);
    color: rgb(92, 92, 92);
  }
  .acc_acc {
    /* padding-right: 115px; */
    margin-left: 0px;
  }
}
