.test_passed_text {
  margin-left: 600px;
  margin-top: -260px;
}

.test_passed_f {
  font-weight: 600;
  padding-bottom: 10px;
}

.test_passed_text svg {
  margin-left: 80px;
  padding-bottom: 10px;
}

.test_passed_s {
  margin-left: 55px;
  font-weight: 600;
  padding-bottom: 40px;
}

.back_to_tests {
  padding: 10px 30px;
  border: none;
  color: white;
  background-color: #eba14f;
  font-weight: 600;
  margin-left: 55px;
}

@media (max-width: 767px) {
  .test_passed_text {
    margin-left: 0;
    margin-top: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .back_to_tests {
    margin-left: 0;
  }
  .test_passed_text svg {
    margin-left: 0;
  }
  .test_passed_s {
    margin-left: 0;
  }
}
