.fifth_section {
  padding-top: 60px;
  height: 540px;
}

.sci_path {
  color: rgba(0, 0, 0, 0.7);
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 83.333% */
  text-transform: uppercase;
}

.reg_portal {
  padding-top: 60px;
  height: 85%;
}

.learn_tech {
  padding-top: 60px;
  height: 85%;
  padding-left: 50px;
}

.pass_test {
  height: 77.5%;
}

.labs_res {
  height: 70%;
  padding-left: 50px;
}

.learn_more {
  margin-top: -20px;
  margin-left: 15px;
  display: inline-flex;
  padding: 17px 62px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  border: 2px solid #000;
  background-color: #fff;
  transition: 0.1s all ease;
}

.learn_more:hover {
  border: 2px solid rgba(79, 79, 79, 0.7);
  color: rgba(79, 79, 79, 0.7);
}

@media (max-width: 1400px) {
  .col-md-8 .sci_path {
    font-size: 28px;
  }

  .reg_portal {
    height: 70%;
  }

  .learn_tech {
    height: 70%;
    margin-left: -30px;
  }
  .pass_test {
    height: 56%;
  }

  .labs_res {
    height: 50.5%;
    margin-left: -30px;
  }
  .learn_more {
    width: 45%;
    height: 100%;
    font-size: 15px;
    margin-top: -100px;
  }
}

@media (max-width: 1200px) {
  .col-md-10 .sci_path {
    font-size: 22px;
  }

  .reg_portal {
    height: 60%;
  }

  .learn_tech {
    height: 60%;
    margin-left: -30px;
  }
  .pass_test {
    height: 41.3%;
  }

  .labs_res {
    height: 37.3%;
    margin-left: -30px;
  }
  .learn_more {
    width: 55%;
    height: 110%;
    font-size: 11px;
    margin-top: -20px;
  }
}

@media (max-width: 992px) {
}
