.sixth_section {
  height: 960px;
}

.study_plan {
  color: rgba(0, 0, 0, 0.7);
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  text-transform: uppercase;
}

.study_plan_sec {
  color: #342c41;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px; /* 162.5% */
  padding-top: 25px;
}

.gifka {
  padding-top: 30px;
  /* width: 1300px; */
  width: 100%;
}

@media (max-width: 1200px) {
  .study_plan {
    font-size: 28px;
  }
  .study_plan_sec {
    font-size: 20px;
    line-height: 30px;
  }
  .sixth_section {
    height: 760px;
  }
  .col-md-10 .contacts_text {
    font-size: 28px;
  }
}

@media (min-width: 768px) and (max-width: 1600px) {
  .gifka {
    width: 100%;
  }
}
