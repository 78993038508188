.tests_list {
  color: hsl(200, 19%, 18%);
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  margin-left: 350px;
  margin-top: -290px;

  /* line-height: 125%; */
}

.tests_links {
  padding-top: 20px;
  margin-left: -32px;
}

.test_link_ball:hover {
  font-weight: 600;
}

.test_q {
  color: black;
}

.sec_test {
  margin-top: -10px;
}

.third_test {
  margin-top: -20px;
}

.fourth_test {
  margin-top: -29px;
}

.test_link_ball {
  text-decoration: none;
  color: black;
  font-size: 16px;
  font-weight: 300;
  line-height: 34px;
  list-style-type: none;
}

.acc_acc_test {
  padding: 10px 0px;
  padding-right: 114px;
  padding-left: 10px;
  border: none;
  color: black;
  background-color: #fff;
  font-weight: 600;
  /* display: flex; */
  /* align-items: start; */
}

.acc_acc_test:hover {
  background-color: #eba14f;
  color: white;
}

.acc_test_test {
  padding: 10px 0px;
  padding-right: 134px;
  padding-left: 10px;
  border: none;
  color: #fff;
  background-color: #eba14f;
  font-weight: 600;
}

.acc_test_doc {
  padding: 10px 0px;
  padding-right: 58px;
  padding-left: 10px;
  border: none;
  color: black;
  background-color: white;
  font-weight: 600;
  font-size: 16px;
}

.acc_test_doc:hover {
  background-color: #eba14f;
  color: white;
}

.test_link_tb {
  text-decoration: none;
  color: black;
}

@media (min-width: 767px) and (max-width: 991.98px) {
  .tests_list {
    color: hsl(200, 19%, 18%);
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    margin-left: 260px;
    margin-top: -245px;
  }
  
}
@media (min-width: 300px) and (max-width: 766.98px) {
  .tests_list {
    color: hsl(200, 19%, 18%);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    margin-left: 0px;
    margin-top: 0px;
  }
  .acc_header {
    font-size: 20px;
  }
  .tests_links {
    padding-top: 0;
  }
}
